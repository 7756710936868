import {Alert, Button, CircularProgress, Grid, ListItemText, MenuItem, TextField,} from "@mui/material";
import React, {useEffect} from "react";
import {
  Callback,
  confirmTelemonitoringPrescription,
  CreatePrescriptionFinalResult,
  getTelemonitoringFollowupData,
  RedirectPayload,
  ServiceLevel,
  StockItem,
  TelemonitoringPrescriptionCreateRequest,
} from "../API";
import {useSearchParams} from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CardioLogo from "../byteflies-cardio-logo.svg";

const EPD = () => {
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<Error>();

  const [callback, setCallback] = React.useState<Callback>();
  const [telemonitoringId, setTelemonitoringId] = React.useState<string>();
  const [hospitalId, setHospitalId] = React.useState<string>();
  const [alias, setAlias] = React.useState<string>();
  const [doctor, setDoctor] = React.useState<string>();
  const [stock, setStock] = React.useState<StockItem[]>(
    []
  );
  const [box, setBox] = React.useState<StockItem | undefined>(undefined);
  const [followUpDuration, setFollowUpDuration] = React.useState(0);
  const [serviceLevel, setServiceLevel] = React.useState<ServiceLevel | "choose">("choose");
  const [result, setResult] = React.useState<CreatePrescriptionFinalResult>();

  useEffect(() => {
    const fetchData = async () => {
      const payload = JSON.parse(atob(searchParams.get("data") as string)) as RedirectPayload;
      getTelemonitoringFollowupData(payload.endpoint.url, payload.endpoint.authorization)
        .then((data) => {
          setCallback(payload.endpoint);
          setTelemonitoringId(data.id);
          setHospitalId(data.hospitalId);
          setAlias(data.patientAlias);
          setDoctor(data.doctor);
          setStock(data.stock);
          setLoading(false);
        })
        .catch((error) => {
          setError(error as Error);
          setLoading(false);
        });
    };
    (async () => {
      await fetchData();
    })();
  }, [searchParams]);

  if (result !== undefined) {
    return (
      <Grid container spacing={2} margin={10} maxWidth={"80%"}>
        <Grid item xs={12}>
          <Alert data-cy="patient-created" severity="success">
            Patient created successfully
          </Alert>
        </Grid>
      </Grid>
    );
  }

  let shouldDisableButton = loading ||
    alias === undefined ||
    box === undefined ||
    serviceLevel === "choose" ||
    followUpDuration === 0;

  const durationOptions = [
    <MenuItem key={0} value={0}>
      Select an option
    </MenuItem>,
    <MenuItem key={1} value={1}>
      {`Automatically after 1 day of follow-up`}
    </MenuItem>
  ];
  [2, 3, 4, 5, 6, 7, 14, 21, 30].forEach((d) => (
    durationOptions.push(<MenuItem key={d} value={d}>
      {`Automatically after ${d} days of follow-up`}
    </MenuItem>)
  ));

  console.log(box);
  return (
    <Grid container spacing={2} margin={10} maxWidth={"80%"}>
      <Grid item xs={12}>
        <img src={CardioLogo} alt="CardioCare@Home" height="100"/>
      </Grid>

      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          name="alias"
          data-cy="alias"
          label={"Patient"}
          onChange={(e) => {
            setAlias(e.target.value);
          }}
          value={alias}
          autoComplete="off"
          variant="outlined"
          disabled={loading}
          InputLabelProps={{shrink: true}}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          name="assignedDoctor"
          data-cy="assignedDoctor"
          label={"Doctor"}
          onChange={(e) => {
            const a = e.target.value;
            setDoctor(a);
          }}
          value={doctor}
          autoComplete="off"
          variant="outlined"
          disabled={loading}
          InputLabelProps={{shrink: true}}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="boxSelect"
          required
          data-cy="box"
          select
          fullWidth
          disabled={
            loading ||
            stock.length === 0
          }
          label={
            stock === undefined || stock.length === 0
              ? "There are no boxes available"
              : "Choose a box or pouch"
          }
          error={stock === undefined || stock.length === 0}
          value={box}
          onChange={(e) => {
            const box = e.target.value as any as StockItem;
            if (box !== undefined && typeof box === "object") {
              setBox(box);
              if (box.type === "pouch") {
                setFollowUpDuration(1);
                setServiceLevel("plus");
              } else {
                setServiceLevel("choose");
                setFollowUpDuration(0);
              }
            }
          }}
          InputLabelProps={{shrink: true}}
        >
          {stock.map((option) => (
            <MenuItem id={option.id} key={option.id} value={option as any}>
              {option.id}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      {box && <>
          <Grid item xs={6}>When do you want to stop this patient?</Grid>
          <Grid item xs={6}>
              <TextField
                  data-cy="followup-duration"
                  required
                  disabled={
                    loading ||
                    box.type === "pouch"
                  }
                  fullWidth
                  select
                  label="Followup duration"
                  value={followUpDuration}
                  onChange={(e) => {
                    const stop = e.target.value as any as number;
                    setFollowUpDuration(stop);
                  }}
              >
                {durationOptions}
              </TextField>
          </Grid>
      </>
      }

      {box && <Grid item xs={12}>
          <TextField
              data-cy="service-level"
              required
              select
              fullWidth
              disabled={
                loading ||
                box.type === "pouch"
              }
              label="Service Level"
              value={serviceLevel}
              onChange={(e) => {
                const level = e.target.value as ServiceLevel;
                setServiceLevel(level);
              }}
          >
              <MenuItem key={"choose"} value={"choose"}>
                  <ListItemText
                      primary="Select an option"
                  />
              </MenuItem>
              <MenuItem key={"afib"} value={"afib"}>
                  <ListItemText
                      primary="CardioCare Afib"
                      secondary="Tailored for afib detection: we will annotate atrial fibrillation, tachycardia, bradycardia, and pauses."
                  />
              </MenuItem>
              <MenuItem key={"plus"} value={"plus"}>
                  <ListItemText
                      primary="CardioCare Plus"
                      secondary="Full Holter report: we will annotate atrial fibrillation, bradycardia, pauses, ectopic beats, and ventricular and supraventricular tachycardia."
                  />
              </MenuItem>
          </TextField>
      </Grid>}

      <Grid item xs={6}></Grid>
      <Grid item xs={6}>
        <Button
          data-cy="prescribe"
          fullWidth
          startIcon={loading ? <CircularProgress/> : <PersonAddIcon/>}
          disabled={shouldDisableButton}
          color="primary"
          variant={shouldDisableButton ? "outlined" : "contained"}
          onClick={async () => {
            setLoading(true);
            try {
              const body: TelemonitoringPrescriptionCreateRequest = {
                telemonitoringId: telemonitoringId as string,
                hospitalId: hospitalId as string,
                alias: alias as string,
                serviceLevel: serviceLevel as ServiceLevel,
                boxId: (box as StockItem).id,
                dataCollectionPeriodDays: followUpDuration,
                assignedDoctor: doctor,
              };
              const r = await confirmTelemonitoringPrescription(
                `${callback!.url}/confirm`,
                callback!.authorization,
                body
              );
              setResult(r);
              setError(undefined);
            } catch (error) {
              console.error(error);
              setError(error as Error);
            } finally {
              setLoading(false);
            }
          }}
        >
          Create a new patient
        </Button>
      </Grid>

      {error !== undefined && (
        <Grid item xs={12}>
          <Alert severity="error">{error.message}</Alert>
        </Grid>
      )}
    </Grid>
  );
};

export default EPD;
