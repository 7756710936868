import axios from "axios";

/**
 * This is the data that is sent (base64 encoded) in the q query string parameter.
 */
export type ServiceLevel = "afib" | "plus";

export type BoxType = "box" | "pouch" | "starter-kit";

export interface CreatePrescriptionFinalResult {
  success: boolean;
}

export interface StockItem {
  id: string;
  type: BoxType;
}

export interface RedirectPayload {
  endpoint: Callback;
}

export interface Callback {
  url: string;
  authorization: string;
}

export interface TelemonitoringPrescriptionCreateRequest {
  telemonitoringId: string;
  hospitalId: string;
  alias: string;
  serviceLevel: ServiceLevel;
  boxId: string;
  dataCollectionPeriodDays: number;
  assignedDoctor?: string;
}

export interface TelemonitoringFollowupData {
  id: string;
  hospitalId: string;
  patientAlias: string;
  doctor: string;
  stock: StockItem[];
}

export async function getTelemonitoringFollowupData(url: string, authorization: string): Promise<TelemonitoringFollowupData> {
  const response = await axios.get<TelemonitoringFollowupData>(url, {
    headers: {Authorization: authorization},
  });
  return response.data;
}

export const confirmTelemonitoringPrescription = async (
  url: string,
  authorization: string,
  body: TelemonitoringPrescriptionCreateRequest
) => {
  if (
    body.telemonitoringId === undefined ||
    typeof body.telemonitoringId !== "string"
  ) {
    throw new Error("telemonitoringId is required and must be a string");
  } else if (body.dataCollectionPeriodDays === undefined || isNaN(body.dataCollectionPeriodDays)) {
    throw new Error("dataCollectionPeriodDays is required and must be a number");
  } else if (body.boxId === undefined || typeof body.boxId !== "string") {
    throw new Error("box id is required and must be a string");
  }
  console.log("prescribe", url, body);
  const r = await axios.post<CreatePrescriptionFinalResult>(url, body, {
    headers: {Authorization: authorization},
  });
  console.log("prescribe", r.status, r.data);
  return r.data;
};
