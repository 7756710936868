import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import EPD from "./components/EPD";
import { createTheme } from "@mui/material/styles";
import { blue, grey } from "@mui/material/colors";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
const dashboardTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-outlinedGeneric": {
            color: grey[800],
            borderColor: grey[600],
            "&:hover": {
              borderColor: grey[800],
              backgroundColor: "rgba(0,0,0, 0.10)",
            },
          },
          "&.MuiButton-containedPrimary": {
            color: "white",
            borderColor: "black",
            backgroundColor: "black",
            "&:hover": {
              borderColor: "#242629",
              backgroundColor: "#242629",
            },
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#242629",
      dark: "#black",
    },
    secondary: blue,
  },
});
const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);
  root.render(
    <ThemeProvider theme={dashboardTheme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<EPD />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
